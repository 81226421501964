class CharacterInput {
  constructor () {
    this.input = document.querySelector('.input-to-count')
    this.span = document.querySelector('.count-characters')
  }

  start () {
    this.input.addEventListener('input', () => {
      const currentLength = this.input.value.length
      const currentValue = this.input.value

      if(currentLength > 200){ this.input.value = currentValue.slice(0, -1) }

      const finalLength = this.input.value.length
      this.input.setCustomValidity(finalLength > 200 ? 'Maximum 200 characters' : '')

      this.span.innerHTML = `${finalLength} / 200`
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const input = document.querySelector('.input-to-count')
  if(input == null) { return }
  new CharacterInput().start()
})

document.addEventListener("turbolinks:before-cache", () => {
  let input = document.querySelector('.input-to-count')
  let span = document.querySelector('.count-characters')

  if(span == null) { return }
  span.innerHTML = '0 / 200'
  input.value = ''
})