class Password {
  constructor () {
    this.checkBoxes = document.querySelectorAll('input[type=checkbox].show-hide')
  }
  
  start () {
    this.addEvent()
  }
 
  addEvent () {
    this.checkBoxes.forEach(chb => {
      chb.addEventListener('click', () => {
        let input = document.querySelector(`[data-input=${chb.id}]`)

        if (input.type === 'password') {
          input.type = "text"
          return
        }
        
        input.type = "password"
      })
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const checkBoxes = document.querySelectorAll('input[type=checkbox].show-hide')
  if(checkBoxes == null) { return }
  
  new Password().start()
})