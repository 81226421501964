class ContactController {

  constructor(element) {
    this.element = element;

    this.enableSubmitButton = this.enableSubmitButton.bind(this)
    this.disableSubmitButton = this.disableSubmitButton.bind(this)
  }

  connect() {
    this.submitButtonTarget = this.element.querySelector('[data-contact-target=submitButton]')
    window.addEventListener('contactform:captcha-validated', this.enableSubmitButton)
    window.addEventListener('contactform:captcha-expired', this.disableSubmitButton)
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

}

document.addEventListener('turbolinks:load', () => {

  const contactForm = document.querySelector('[data-controller=contact]')
  if(!contactForm) { return }

  new ContactController(contactForm).connect()

});



document.addEventListener('turbolinks:load', () => {
  const confirmationModal = document.querySelector('.ready-modal')
  if (confirmationModal == null) { return }
  $('#confirmation-modal').modal('show')
})


window.validCaptcha = () => {
  window.dispatchEvent(new Event('contactform:captcha-validated'))
}

window.invalidCaptcha = () => {
  window.dispatchEvent(new Event('contactform:captcha-expired'))
}

