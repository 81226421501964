class PasswordValidates {
  constructor() {
    this.password = document.querySelector('#professional_password')
    this.password_confirmation = document.querySelector('#professional_password_confirmation')
    this.lengthpswd = document.querySelector('#length')
    this.capitalpswd = document.querySelector('#capital')
    this.numberpswd = document.querySelector('#number')
    this.confirmationpswd = document.querySelector('#confirmation')
    this.sendBtn = document.querySelector('.send-btn')
  }

  start() {
    this.validatePassword(this.password, this.password_confirmation, this.lengthpswd, this.capitalpswd, this.numberpswd, this.sendBtn, this.confirmationpswd)
  }

  validatePassword(password, password_confirmation, lengthpswd, capitalpswd, numberpswd, sendBtn, confirmationpswd) {
    
    if (password.value.length < 8) {
      lengthpswd.style.color = "red";
    } else {
      lengthpswd.style.color = "green";
    }

    if (!password.value.match(/[A-Z]/)) {
      capitalpswd.style.color = "red";
    } else {
      capitalpswd.style.color = "green";
    }

    if (!password.value.match(/\d/)) {
      numberpswd.style.color = "red";
    } else {
      numberpswd.style.color = "green";
    }
    if(password.value === password_confirmation.value){
      confirmationpswd.style.color = "green";
    }else{
      confirmationpswd.style.color = "red";
    }
    if (password.value.length < 8 ||
      !password.value.match(/[A-Z]/) ||
      !password.value.match(/\d/) ||
      !(password.value === password_confirmation.value)) {
      sendBtn.disabled = true
    } else {
      sendBtn.disabled = false
    }
  }
}
document.addEventListener('turbolinks:load', () => {
  const passwordFields = document.querySelector('#new_professional')
  if (passwordFields == null) { return }

  passwordFields.addEventListener('keyup', () =>
    new PasswordValidates().start()
  )

})