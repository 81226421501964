class Deposit {
  constructor() {
    this.container = document.querySelector('.admin-deposit-container')
    this.body = this.container.querySelector('table tbody')
    this.rows = this.body.querySelectorAll('tr')
    this.checkBoxesState = this.container.querySelectorAll('.check-state')
    this.paidRow = this.body.querySelectorAll('tr[data-state=paid]')
    this.unPaidRow = this.body.querySelectorAll('tr[data-state=unpaid]')
  }

  start() {
    this.addClickEventToChkBxs()
  }

  addClickEventToChkBxs() {
    this.checkBoxesState.forEach(chb => {
      chb.addEventListener('click', () => {
        const rows = chb.value == 'paid' ? this.paidRow : this.unPaidRow
        console.log(rows)
        const action = chb.checked ? 'remove' : 'add'
        this.actionInRows(rows, action)
      })
    })
  }

  actionInRows(rows, action) {
    rows.forEach(row => {
      action == 'add' ? row.classList.add('d-none') : row.classList.remove('d-none')
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const container = document.querySelector('.admin-deposit-container')

  if(container == null) { return }

  new Deposit().start()
})