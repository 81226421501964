class Charge {
  constructor() {
    this.container = document.querySelector('.charges-container')
    this.moreChargesBtn = this.container.querySelector('.more-charges')
    this.sendBtn = this.container.querySelector('.send-btn')
    this.firstRow = this.container.querySelector('.charge-2')
    this.initialsRows = this.container.querySelectorAll('.row.body')
    this.emailInputs = this.container.querySelectorAll('input[data-content=email]')
  }

  start() {
    this.addKeyPresstoRows(this.initialsRows)
    this.addChangeToEmailInput(this.emailInputs)
    this.addClickToMoreChargesBtn()
  }

  addChangeToEmailInput(inputs) {
    inputs.forEach(input => {
      input.addEventListener('change', () => this.checkCustomerExistence(input.value, input))
    })
  }

  checkCustomerExistence(text, input) {
    const url = '/professional/charges/check_customer_existence'

    fetch(url, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        'email': text
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.error(error)
    })
    .then(data => {
      let first_name = document.querySelector(`#${input.dataset.charge}_first_name`)
      let last_name = document.querySelector(`#${input.dataset.charge}_last_name`)
      let second_last_name = document.querySelector(`#${input.dataset.charge}_second_last_name`)
      let ar = [first_name, last_name, second_last_name]

      ar.forEach(inp => {
        let state_data
        if(data.state == 'FOUND'){
          state_data = data[inp.dataset.content] == null ? 'NO_DATA' : 'WITH_DATA'
        }else state_data = 'NO_DATA'
        
        inp.value = state_data == 'WITH_DATA' ? data[inp.dataset.content] : ''
        inp.readOnly = state_data == 'WITH_DATA' ? true : false
      })
    })
  }

  addKeyPresstoRows(rows) {
    rows.forEach(row => {
      const inputs = row.querySelectorAll('input:not([type=hidden])')

      inputs.forEach(input => {
        input.addEventListener('keypress', () => {
          if(row.classList.contains('charge-2')) { return }

          const checkAllEmpty = this.checkEmptyInput(inputs)
          this.addRequired(inputs, checkAllEmpty)
        })
      })
    })
  }

  checkEmptyInput(inputs) {
    let empty = true
    inputs.forEach(input => {
      if(input.value != '') { empty = false }
    })
    return empty
  }

  addRequired(inputs, checkAllEmpty) {
    inputs.forEach(input => {
      if(checkAllEmpty) { input.required = false; return }
      input.required = true
    })
  }

  addClickToMoreChargesBtn() {
    this.moreChargesBtn.addEventListener('click', btn => {
      const nextNumber = this.countRowsBody() + 1
      const lastNumber = nextNumber - 1
      this.cloneRowBody(lastNumber, nextNumber)

      this.sendBtn.value = nextNumber
    })
  }

  countRowsBody() {
    return this.container.querySelectorAll('.row.body').length
  }

  cloneRowBody(lastNumber, nextNumber) {
    let newRow = this.firstRow.cloneNode(true)
    this.cleanInputs(newRow)
    newRow.classList.remove('charge-2')
    newRow.classList.add(`charge-${nextNumber}`)

    this.changeNameAttributes(newRow, `charge-${nextNumber}`)
    this.addKeyPresstoRows([newRow])

    const currentLastRow = this.container.querySelector(`.charge-${lastNumber}`)
    let parent = currentLastRow.parentNode

    parent.insertBefore(newRow, currentLastRow.nextSibling)
  }

  cleanInputs(row) {
    row.childNodes.forEach(child => {
      if(child.classList.contains('d-none')) { return }
      
      let input = child.querySelector('input')
      input.value = ''
      input.required = false
      input.readOnly = false

      if(input.dataset.content == 'email') { this.addChangeToEmailInput([input]) }
    })
  }

  changeNameAttributes(row, newName) {
    row.childNodes.forEach(col => {
      let input = col.querySelector('input')
      input.name = input.name.replace('charge-2', newName)
      input.id = input.id.replace('charge-2', newName)
      input.dataset.charge = input.dataset.charge.replace('charge-2', newName)
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const container = document.querySelector('.charges-container')

  if(container == null) { return }

  new Charge().start()
})
