class Payment {
    constructor() {
      this.container = document.querySelector('.admin-payment-container')
      console.log("container", this.container);
      this.body = this.container.querySelector('table tbody')
      this.rows = this.body.querySelectorAll('tr')
      this.checkBoxesState = this.container.querySelectorAll('.check-state')
      this.failedRow = this.body.querySelectorAll('tr[data-state=created]')
      this.completedRow = this.body.querySelectorAll('tr[data-state=completed]')
      this.nullifiedRow = this.body.querySelectorAll('tr[data-state=nullified]')
    }
  
    start() {
      this.addClickEventToChkBxs()
    }
  
    addClickEventToChkBxs() {
      this.checkBoxesState.forEach(chb => {
        chb.addEventListener('click', () => {
          const rows = chb.value == 'created' ? this.failedRow : chb.value == 'completed' ? this.completedRow : this.nullifiedRow
          console.log(rows)
          const action = chb.checked ? 'remove' : 'add'
          this.actionInRows(rows, action)
        })
      })
    }
  
    actionInRows(rows, action) {
      rows.forEach(row => {
        action == 'add' ? row.classList.add('d-none') : row.classList.remove('d-none')
      })
    }
  }
  
  document.addEventListener('turbolinks:load', () => {
    const container = document.querySelector('.admin-payment-container')
  
    if(container == null) { return }
  
    new Payment().start()
  })