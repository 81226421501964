// https://github.com/ibnujakaria/multiple-select-js
// https://developer.snapappointments.com/bootstrap-select/

// import MultipleSelect from 'multiple-select-js'

// document.addEventListener('turbolinks:load', () => {
//   new MultipleSelect('.multiple-select', {
//     placeholder: 'Selecciona un rubro'
//   })
// })

import "bootstrap";
import 'bootstrap-select'
import 'bootstrap-select/dist/js/bootstrap-select'
import 'bootstrap-select/dist/js/bootstrap-select.min.js'
import 'bootstrap-select/dist/js/i18n/defaults-es_CL'


document.addEventListener('turbolinks:load', () => {
  $.fn.selectpicker.Constructor.BootstrapVersion = '4';
  $.fn.selectpicker.Constructor.DEFAULTS.multipleSeparator = ' | ';
  
  $('.selectpicker').selectpicker({
    liveSearch: 'true',
    selectedTextFormat: 'count > 4',
    size: '10',
    actionsBox: 'true',
    liveSearchPlaceholder: 'Buscar',
    liveSearchStyle: 'contains'
  });
})

document.addEventListener('turbolinks:before-cache', () => {
  $('.selectpicker').selectpicker('destroy')
})
