import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'
import { Spanish } from 'flatpickr/dist/l10n/es'
require('flatpickr/dist/themes/material_green.css')

document.addEventListener('turbolinks:load', () => {
  flatpickr('.datepicker', {
    locale: Spanish,
    dateFormat: 'd/m/Y'
  });
})
