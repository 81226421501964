class Transfer {
  constructor() {
    this.container = document.querySelector('.admin-transfer-container')
    this.saveButton = this.container.querySelector('.save-btn')
    this.checkBoxes = this.container.querySelectorAll('input[type="checkbox"]')
  }

  start() {
    this.addClickEventToChkBxs()
  }

  addClickEventToChkBxs() {
    this.checkBoxes.forEach(chb => {
      chb.addEventListener('click', () => {
        const stateCheckBoxes = this.checkAllChkBxs(this.checkBoxes)
        if(!stateCheckBoxes) { this.saveButton.disabled = false; return }

        this.saveButton.disabled = true
      })
    })
  }

  checkAllChkBxs(checkBxs) {
    let noneChecked = true
    checkBxs.forEach(chb => {
      if(chb.checked) { noneChecked = false }
    })

    return noneChecked
  }
}

document.addEventListener('turbolinks:load', () => {
  const container = document.querySelector('.admin-transfer-container')

  if(container == null) { return }

  new Transfer().start()
})