import { Controller } from "stimulus"

let regionPersonalInfo, communePersonalInfo
let regionBillingInfo, communeBillingInfo
let url

export default class extends Controller {
  static targets = ["field"]
  connect() {
    url = '../../../region'
    let fields = this.fieldTargets
    console.debug(fields)
    regionPersonalInfo = fields.find( f => f.id == 'professional_personal_region')
    communePersonalInfo = fields.find( f => f.id == 'professional_personal_commune')
    console.debug(`1communePersonalInfo ${communePersonalInfo}`)
    console.debug(`1regionPersonalInfo ${regionPersonalInfo}`)
    regionBillingInfo = fields.find( f => f.id == 'billing_datum_region')
    communeBillingInfo = fields.find( f => f.id == 'billing_datum_commune')
    
    this.changeRegionBillingInfo()
    this.changeRegionPersonalInfo()
  }

  changeRegionPersonalInfo(){
    console.debug(`2communePersonalInfo ${communePersonalInfo}`)
    console.debug(`2regionPersonalInfo ${regionPersonalInfo}`)
    this.fetchCommune(communePersonalInfo, regionPersonalInfo)
  }

  changeRegionBillingInfo(){
    this.fetchCommune(communeBillingInfo, regionBillingInfo)
  }

  fetchCommune(targetField, region){
    console.debug(`targetField ${targetField}`)
    console.debug(`region ${region}`)
    fetch(`${url}/${region}/communes_by_region`)
      .then(response => response.json())
      .then(data => {
        targetField.innerHTML = ''
        data.forEach(item => {
          const opt = document.createElement('option')
          opt.value = item.name
          opt.innerHTML = item.name
          targetField.appendChild(opt)
        })
        $(targetField).selectpicker('refresh');
      })
      .catch(error => console.error(error))
  }

}
