class PreviewImage {
  constructor () {
    this.containerImage = document.querySelector('.container-profile-image.edit')
    this.input = document.querySelector('.image-upload')
    this.image = document.querySelector('.profile-image')
  }

  start () {
    this.input.addEventListener('change', () => {
      this.readUrl(this.input)
    })
  }

  readUrl (input) {
    if (input.files && input.files[0]) {
      let reader = new FileReader()

      reader.onload = e => { this.image.src = e.target.result }
      reader.readAsDataURL(input.files[0])
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  const containerImage = document.querySelector('.container-profile-image.edit')
  if(containerImage == null) { return }

  new PreviewImage().start()
})