// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start()
require("@rails/ujs").start()
require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const vectors = require.context('../images/vector', true)
const images = require.context('../images', false)
const footer = require.context('../images/footer', false)
const header = require.context('../images/header', false)
import 'css/site'

require("trix")
require("@rails/actiontext")

import 'plugins/main'
import 'js/admin/deposits'
import 'js/admin/payments'
import 'js/admin/transfers'
import 'js/professional/charge'
import 'js/customer/contact'
import 'js/customer/auto-redirect'
import 'js/customer/payments/add-detail'
import 'js/professional/preview-image'
import 'js/password_validates'
import 'libs/notice_container'
import "controllers"
