document.addEventListener('turbolinks:load', () => {
  const returnButton = document.querySelector('.btn-padpow-return')
  if(!returnButton) { return }

  window.setTimeout(function(){

    window.location.href = returnButton.href;

  }, 10000);
  
})