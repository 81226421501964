class PhoneFormat {
  constructor() {
    this.phoneInputs = document.querySelectorAll('.phone-format')
  }
  start() {
    this.initialPhoneFormat()
  }

  initialPhoneFormat () {
    this.phoneInputs.forEach(input => {
      input.addEventListener('input', () => {
        input.value = input.value.split(' ').join('')
      })
    })
    
  }

}

document.addEventListener('turbolinks:load', () => {
  const phoneInput = document.querySelectorAll('.phone-format')
  if(phoneInput == null) { return }
  new PhoneFormat().start()
})