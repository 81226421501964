class AddDetail {
  constructor () {
    this.checkbox = document.querySelector('.cb-add-detail')
    this.container = document.querySelector('.container-additional-detail')
    this.text_area = document.querySelector('.add-detail')
  }

  start () {
    this.addEvent()
  }

  addEvent () {
    if(!this.checkbox.checked){
      this.container.style.display = 'none'
    }
    this.checkbox.addEventListener('click', () => {
      if(this.checkbox.checked){
        this.container.style.display = 'flex'
      }
      else{
        this.text_area.value = ''
        this.container.style.display = 'none'
      }
    })
  }
}

document.addEventListener('turbolinks:load', () => {
  const addDetailButton = document.querySelector('.cb-add-detail')
  if(!addDetailButton) { return }

  new AddDetail().start()
})