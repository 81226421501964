import 'bootstrap'


document.addEventListener('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()

  $('.password-popover').popover({
    container: 'body'
  })
})
