class RutFormat {
  constructor() {
    this.rutInputs = document.querySelectorAll('.rut-format')
  }
  start() {
    this.initialFormatRut()
  }
  
  initialFormatRut () {
    this.rutInputs.forEach(input => {
      input.addEventListener('input', () => {
        let rut = input.value
        const newRut = rut.replace(/\./g,'').replace(/\-/g, '').trim().toLowerCase()
        const lastDigit = newRut.substr(-1, 1)
        const rutDigit = newRut.substr(0, newRut.length-1)
        let format = ''
        let cont = 1
        for (let i = rutDigit.length; i > 0; i--) {
          const e = rutDigit.charAt(i-1)
          format = e.concat(format)
          if (cont % 3 === 0  && cont <= rutDigit.length - 1){
            format = '.'.concat(format)
          }
          cont++
        }
        input.value = format.concat('-').concat(lastDigit)
  
        const rutClean = newRut.replace(/[.-]/g, '')
                               .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
        const result = this.verifyDigit(rutClean)
        input.setCustomValidity(result ? '' : 'Invalid RUT')
      })
    })
    
  }

  verifyDigit (rut) {
    const digitLast = rut.split('-')[1]
    const digit = digitLast === 'k' ? digitLast : parseInt(digitLast)
    const digitV = this.calculateDigit(rut)
    return digit == digitV
  }

  calculateDigit (rut) {
    let factor = 2
    let sum = 0
    let nums = rut.split('-')[0].replace(/\./g, '')

    for (let i = 0; i < nums.length; i++) {
      const divisor = nums.split('').reverse()
      sum += parseInt(divisor[i]) * factor;
      factor = factor == 7 ? 2 : factor + 1
    }

    const sumNums = Math.floor(sum / 11)
    const resto = sum - (11 * sumNums)
    const endDigit = 11 - resto
    if (endDigit == 11) { return 0 }
    if (endDigit == 10) { return 'k' }
    return endDigit
  } 
}

document.addEventListener('turbolinks:load', () => {
  const rutInput = document.querySelectorAll('.rut-format')
  if(rutInput == null) { return }
  new RutFormat().start()
})